export default {
    async getAll() {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/categories`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch {
            return [];
        }
    },

    async getDetails(id) {
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/categories/${id}`);
        return data;
    },

    async create(data) {
        await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/categories`, {
            name: data.name,
        });
    },

    async update(id, data) {
        await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/categories/${id}`, {
            name: data.name,
        });
    },

    destroyURL(id) {
        return `${process.env.VUE_APP_CORE_API_URL}/services/categories/${id}`;
    },
};
