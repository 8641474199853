<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && $emit('close')"
        @open="getCategories"
        @closed="resetForm"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('services.add_service') }}
                </p>
            </div>
        </span>

        <div class="mb-14 mt-8 px-10">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" @submit.native.prevent @keyup.native.enter="addService">
                <el-form-item :label="$t('services.category')">
                    <el-select
                        v-model="formData.category_id"
                        v-loading="$wait.is('loading.categories')"
                        :placeholder="$t('services.category')"
                        element-loading-spinner="el-custom-spinner"
                        class="w-full"
                        filterable
                    >
                        <el-option
                            :label="$t('services.none')"
                            :value="null"
                        />
                        <el-option
                            v-for="category in categoriesData"
                            :key="category.id"
                            :label="category.name"
                            :value="category.id"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('services.name')" prop="name">
                    <div class="flex">
                        <el-input v-model="formData.name" />
                        <el-color-picker v-model="formData.color" class="ml-2" :predefine="predefinedColors" />
                    </div>
                </el-form-item>

                <el-form-item :label="$t('services.price')" prop="price" class="el-input--small">
                    <money v-model="formData.price" class="el-input__inner" v-bind="money" />
                </el-form-item>

                <el-form-item :label="$t('services.price_type')">
                    <el-radio-group v-model="formData.price_type" @change="priceTypeChanged">
                        <el-radio-button label="hourly">
                            {{ $t('services.hourly') }}
                        </el-radio-button>
                        <el-radio-button label="once">
                            {{ $t('services.once') }}
                        </el-radio-button>
                        <!-- <el-radio-button label="timbank">
                            Timbank
                        </el-radio-button> -->
                    </el-radio-group>
                </el-form-item>

                <div v-if="formData.price_type === 'timbank'" class="grid grid-cols-2">
                    <el-form-item :label="$t('services.timbank_hours')" prop="timbank_volume_hours">
                        <el-input-number v-model="formData.timbank_volume_hours" :precision="0" :min="1" :controls-position="'right'" />
                    </el-form-item>
                    <el-form-item :label="$t('services.timbank_minutes')" prop="timbank_volume_minutes">
                        <el-input-number
                            v-model="formData.timbank_volume_minutes"
                            :precision="0"
                            :min="0"
                            :max="45"
                            :step="15"
                            :controls-position="'right'"
                        />
                    </el-form-item>
                </div>

                <div class="grid grid-cols-2">
                    <el-form-item label="RUT">
                        <el-switch
                            v-model="formData.rut"
                            active-color="#13ce66"
                            inactive-color="#20a0ff"
                            :active-value="1"
                            :inactive-value="0"
                            :active-text="$t('common.yes')"
                            :inactive-text="$t('common.no')"
                            :disabled="formData.rot === 1"
                        />
                    </el-form-item>

                    <el-form-item label="ROT">
                        <el-switch
                            v-model="formData.rot"
                            active-color="#13ce66"
                            inactive-color="#20a0ff"
                            :active-value="1"
                            :inactive-value="0"
                            :active-text="$t('common.yes')"
                            :inactive-text="$t('common.no')"
                            :disabled="formData.rut === 1"
                        />
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('adding')" @click="addService">{{ $t('common.add') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';
import Api from '../services.api';
import ApiCategories from '../../services_categories/categories.api';

Vue.use(Money);

export default {
    components: { Money },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            categoriesData: [],
            money:          {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            formData: {
                name:                   '',
                invoice_name:           '',
                price:                  100.00,
                tax:                    25,
                timbank_volume_hours:   1,
                timbank_volume_minutes: 0,
                rut:                    0,
                rot:                    0,
                color:                  '#20a0ff',
                category_id:            null,
                price_type:             'hourly',
            },
            predefinedColors: [
                '#c92918', '#00d1be', '#0097d1', '#3a983a', '#0071d1', '#8433de', '#db33de', '#de3372', '#33deaf', '#9a8974',
            ],
            rules: {
                timbank_volume_hours:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                timbank_volume_minutes: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                name:                   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                price:                  [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async getCategories() {
            try {
                this.$wait.start('loading.categories');
                this.categoriesData = await ApiCategories.getAll();
            } finally {
                this.$wait.end('loading.categories');
            }
        },

        async addService() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            try {
                this.$wait.start('adding');
                await Api.create(this.formData);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } finally {
                this.$wait.end('adding');
                this.closeModal();
            }
        },

        priceTypeChanged() {
            if (this.formData.price_type !== 'timbank') {
                this.formData.timbank_volume = null;
            }
        },

        closeModal() {
            this.$emit('close');
        },

        resetForm() {
            this.$refs.form.resetFields();
            this.formData = {
                name:                   '',
                invoice_name:           '',
                price:                  100.00,
                tax:                    25,
                timbank_volume_hours:   1,
                timbank_volume_minutes: 0,
                rut:                    0,
                rot:                    0,
                color:                  '#20a0ff',
                category_uuid:          null,
                price_type:             'hourly',
            };
        },
    },
};
</script>
